import React from 'react';
import { Button, Dialog, Grid, makeStyles } from '@material-ui/core';

const UserAddedToFaceoffQueue = ({ open, setOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} className={classes.dialog}>
      <Grid
        container
        direction='column'
        className='main-container'
        alignItems='center'
      >
        <p className='title'>Face-Off Queue</p>
        <p className='subtitle'>
          You are currently put in a queue for your Face-Off. Your either add a
          guest to your stream and instantly Face-Off against them or wait until
          we find you one.
        </p>
        <Grid item container className={classes.buttonRow}>
          <Button
            variant='contained'
            className='btn btn-continue'
            color='primary'
            onClick={handleClose}
          >
            Wait in Queue
          </Button>
          <Button
            variant='outlined'
            className='btn btn-cancel'
            color='primary'
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UserAddedToFaceoffQueue;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      background: theme.palette.common.lightPink,
      borderRadius: '12px',
      width: '100%',
      maxWidth: '450px',
    },
    '& .main-container': {
      padding: '25px',
    },

    '& .title': {
      fontSize: '24px',
      color: theme.palette.primary.main,
      fontWeight: '500',
      textAlign: 'center',
      margin: '0px',
    },
    '& .subtitle': {
      margin: '0px',
      marginTop: '16px',
    },
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '20px',
    '& .btn': {
      width: '100%',
      borderRadius: '5px',
      textTransform: 'none',
      borderRadius: '25px',
      height: '40px',
      fontSize: '16px',
    },
  },
}));
