import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { GetApp } from './pages/GetApp/GetApp';
import { SignInScreen } from './pages/SignInScreen/SignInScreen';
import { HelpCenter } from './pages/Help/HelpCenter';
import { CategoryDetails } from './pages/Help/CategoryDetails';
import { AllTabs } from './pages/TabsContainer/AllTabs';
import { Live } from './pages/Live/Live';
import { Stream } from './pages/LiveStream/Stream';
import JoinStream from './pages/LiveStream/JoinStream';
import { Battle } from './pages/Battle/Battle';
import Call from './components/callDialog/Call';
import { SignUp } from './pages/RegisterSteps/SignUp/SignUp';

import VideoCall from './pages/videoCall/VideoCall';
import AudioCall from './pages/audioCall/AudioCall';

import Prompts from './pages/RegisterSteps/Prompts/Prompts';

import { useDispatch } from 'react-redux';
import { getUser, subscribetTopic } from './http/index';
import { submit } from './store/user';
import { SocketContext } from './http/socket';
import {
  onMessageListener,
  subscribeTokenToTopic,
  getToken,
} from './firebaseInit';
import ProtectedRoute from './pages/ProtectedRoute/ProtectedRoute';
import LiveLoop from './pages/LiveStream/LiveLoop';
import { MyProvider } from './utils/contextProvider';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CameraVideoUpload from './pages/CameraVideoUpload/CameraVideoUpload';
import TermsAndConditions from './pages/TerrmsAndConditions/TermsAndConditions';
import { Identify } from './pages/RegisterSteps/Identify/Identify';
import { DatingPref } from './pages/RegisterSteps/DatingPref/DatingPref';
import { Height } from './pages/RegisterSteps/Height/Height';
import Location from './pages/RegisterSteps/Location/Location';
import { Body } from './pages/RegisterSteps/Body/Body';
import { Origin } from './pages/RegisterSteps/Origin/Origin';
import { Education } from './pages/RegisterSteps/Education/Education';
import { Religion } from './pages/RegisterSteps/Religion/Religion';
import { Children } from './pages/RegisterSteps/Children/Children';
import { LifeStyle } from './pages/RegisterSteps/LifeStyle/LifeStyle';
import { SelectImage } from './pages/RegisterSteps/SelectImage/SelectImage';
import { setGender, setLocation } from './store/Reducers/filters';
import { getGenderFilterPreference } from './utils';
import AgoraClientProvider from './context/AgoraContext';
import { PreRegisteration } from './pages/PreRegisteration/PreRegisteration';
import PreRegisterForm from './pages/PreRegisterForm/PreRegisterForm';

function App(props) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [call, setCall] = useState(false);
  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      // props.history.push("/getapp");
    } else {
      if (props.location.pathname === '/getapp' && window.innerWidth > 1200) {
        props.history.goBack();
      }
    }
  };

  const fetchUser = async () => {
    try {
      if (localStorage.getItem('token')) {
        const { data } = await getUser();
        dispatch(submit(data));
        dispatch(
          setGender(
            getGenderFilterPreference(
              data.data.identify.gender,
              data.data.date_preference.interested_gender
            )
          )
        );
        dispatch(
          setLocation({
            lat: +data.data.location.lat,
            lon: +data.data.location.lon,
          })
        );
        socket.emit('login', data.data._id);
        const step = data.data.step;
        const token = await getToken();
        subscribetTopic({
          topic: 'liveuser',
          deviceToken: token,
          isSubscribe: true,
        });
        subscribetTopic({
          topic: `${data.data._id}_liveloop`,
          deviceToken: token,
          isSubscribe: true,
        });
        subscribetTopic({
          topic: `${data.data._id}_joinlive`,
          deviceToken: token,
          isSubscribe: true,
        });
        subscribetTopic({
          topic: 'delliveuser',
          deviceToken: token,
          isSubscribe: true,
        });
        subscribetTopic({
          topic: `${data.data._id}_schedule`,
          deviceToken: token,
          isSubscribe: true,
        });
        subscribetTopic({
          topic: `${data.data._id}_faceoff`,
          deviceToken: token,
          isSubscribe: true,
        });
        // subscribeTokenToTopic(token, 'liveuser');
        // subscribeTokenToTopic(token, `${data.data._id}_liveloop`);
        // subscribeTokenToTopic(token, `${data.data._id}_joinlive`);
        // subscribeTokenToTopic(token, 'delliveuser');
        // subscribeTokenToTopic(token, `${data.data._id}_schedule`);
        // subscribeTokenToTopic(token, `${data.data._id}_faceoff`);
        const res = await getUser();
        dispatch(submit(res.data));
        switch (step) {
          case '/home':
            if (props.history.location.pathname === '/') {
              props.history.push('home');
            }
            break;
          default:
            break;
        }
      }
    } catch (err) {
      // props.history.replace('/');
      console.log(err);
    }
  };
  useEffect(() => {
    fetchUser();
    (async () => {
      onMessageListener().then(data => console.log(data));
    })();

    return () => {
      window.BeforeUnloadEvent(() => {
        if (socket !== null) socket.emit('disconnect');
      });
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      // if (socket !== null) socket.emit("disconnect", userState._id);
      window.removeEventListener('resize', handleResize);
    };
  });
  useEffect(() => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("can't access the location right now ::");
    }
  });
  function showPosition(position) {
    localStorage.setItem('latitude', position.coords.latitude);
    localStorage.setItem('Longitude', position.coords.longitude);
  }
  return (
    <MyProvider>
      <AgoraClientProvider>
        <div className='App'>
          <Switch>
            <Route exact path='/getapp' component={GetApp} />
            <Route path='/' exact>
              <PreRegisteration />
              {/* <Login /> */}
            </Route>
            {/* <Route path='/pre-sign-up' component={PreRegisteration} /> */}
            <Route path='/pre-register' component={PreRegisterForm} />
            <Route path='/signin' component={SignInScreen} />
            <Route path='/register/sign-up' component={SignUp} />
            <Route path='/register/indentify' component={Identify} />
            <Route path='/register/dating-preference' component={DatingPref} />
            <Route path='/register/height' component={Height} />
            <Route path='/register/location' component={Location} />
            <Route path='/register/body-type' component={Body} />
            <Route path='/register/origin' component={Origin} />
            <Route path='/register/education' component={Education} />
            <Route path='/register/religion' component={Religion} />
            <Route path='/register/children' component={Children} />
            <Route path='/register/lifestyle' component={LifeStyle} />
            <Route path='/register/profile-picture' component={SelectImage} />
            <ProtectedRoute path='/home' component={AllTabs} />
            <Route path='/helpcenter' exact>
              <HelpCenter />
            </Route>
            <Route path='/helpcenter/details' exact>
              <CategoryDetails />
            </Route>

            <Route path='/privacy-policy' exact component={PrivacyPolicy} />
            <Route
              path='/terms-and-conditions'
              exact
              component={TermsAndConditions}
            />
            <ProtectedRoute path='/uploads' component={Prompts} />
            <ProtectedRoute
              path='/record-prompt'
              component={CameraVideoUpload}
            />
            <ProtectedRoute path='/live' component={Live} />
            <ProtectedRoute path='/stream' component={Stream} />
            <ProtectedRoute path='/joinstream' component={JoinStream} />
            <ProtectedRoute path='/videochat' exact component={VideoCall} />
            <ProtectedRoute path='/voicechat' component={AudioCall} />
            <ProtectedRoute path='/liveloop' component={LiveLoop} />
            {/* <ProtectedRoute path='/faceoff' component={Battle} /> */}
            <Redirect from='*' to='/' exact />
          </Switch>
          <Call open={call} setOpen={setCall} />
        </div>
      </AgoraClientProvider>
    </MyProvider>
  );
}
export default App;
